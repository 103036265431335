import { css } from 'emotion'
import React, { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { IntercomActionsProvider, useIntercom, usePendo } from '../modules/analytics'
import { getAccessToken, getAuthView } from '../modules/auth/selectors'
import DemoModeContextProvider from '../modules/demo'
import { Dialogue } from '../modules/dialogue'
import ClientMatterNumberProvider from '../modules/expert/components/ClientMatterNumberProvider'
import FilterContextProvider from '../modules/filter/components/FilterContextProvider'
import { HttpStatus } from '../modules/http/types'
import NavBar from '../modules/navigation/components/NavBar'
import NavDrawer from '../modules/navigation/components/NavDrawer'
import { NotificationContainer } from '../modules/notification'
import { ProtectedRoute, getPathname } from '../modules/router'
import FeatureModal from '../modules/session/components/FeatureModal'
import { hasSession, makeGetAccesses } from '../modules/session/selectors'
import { Access } from '../modules/session/types'
import ProductInfo from './Footer/ProductInfo'
import JuristatLoader from './JuristatLoader'
import MaybeRender from './MaybeRender'
import PrivacyPolicy from './PrivacyPolicy'
import RedirectToPersonal from './RedirectToPersonal'
import Splash from './Splash'
import TermsOfSale from './TermsOfSale'
import TermsOfUse from './TermsOfUse'

const styles = {
  content: css({
    display: 'flex',
    flexGrow: 1,
    height: 0,
  }),
  contentContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  }),
  footerContainer: css({
    flexShrink: 0,
  }),
  main: css({
    display: 'flex',
    height: '100% ',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
  }),
  navBar: css({
    flexShrink: 0,
  }),
  navContainer: css({
    display: 'flex',
    flexShrink: 0,
  }),
  navDrawer: css({
    flexShrink: 0,
  }),
  product: css({
    '& > li': {
      '& > svg': {
        fill: 'white',
      },
      listStyle: 'none',
      marginLeft: 0,
    },
    bottom: 0,
    position: 'absolute',
    left: '50%',
  }),
}

const AccountManagement = lazy(
  () => import(/* webpackChunkName: 'account-management' */ '../modules/account-management')
)
const AccountTools = lazy(
  () => import(/* webpackChunkName: 'account-tools' */ '../modules/account-tools')
)
const AlertsConfiguration = lazy(
  () => import(/* webpackChunkName: 'alerts-configuration' */ '../modules/alerts-configuration')
)
const ApiConsole = lazy(() => import(/* webpackChunkName: 'apiConsole' */ '../modules/apiConsole'))
const ApplicationContainer = lazy(
  () => import(/* webpackChunkName: 'application' */ '../modules/application')
)
const Dashboards = lazy(() => import(/* webpackChunkName: 'dashboards' */ '../modules/dashboards'))
const Sponsorships = lazy(
  () => import(/* webpackChunkName: 'sponsorships' */ '../modules/sponsorships')
)
const ConfirmEmail = lazy(
  () =>
    import(/* webpackChunkName: 'auth-confirm-email' */ '../modules/auth/components/ConfirmEmail')
)
const DownloadFile = lazy(
  () =>
    import(/* webpackChunkName: 'download-file' */ '../modules/download/components/DownloadFile')
)
const Drafting = lazy(() => import(/* webpackChunkName: 'drafting' */ '../modules/drafting'))
const Examiner = lazy(() => import(/* webpackChunkName: 'examiner' */ '../modules/examiner'))
const Expert = lazy(() => import(/* webpackChunkName: 'expert' */ '../modules/expert'))
const ExpertSearch = lazy(
  () => import(/* webpackChunkName: 'expert-search' */ '../modules/expert-search')
)
const Home = lazy(() => import(/* webpackChunkName: 'home' */ '../modules/home'))
const Intelligence = lazy(
  () => import(/* webpackChunkName: 'intelligence' */ '../modules/intelligence')
)
const Login = lazy(
  () => import(/* webpackChunkName: 'auth-login' */ '../modules/auth/components/Login')
)
const Omnisearch = lazy(
  () =>
    import(/* webpackChunkName: 'omnisearch' */ '../modules/omnisearch/components/OmnisearchPage')
)
const Patent = lazy(() => import(/* webpackChunkName: 'patent' */ '../modules/patent'))
const ReturnHandler = lazy(
  () => import(/* webpackChunkName: 'auth-return' */ '../modules/auth/components/ReturnHandler')
)
const ScraperManagement = lazy(
  () => import(/* webpackChunkName: 'scraper-management' */ '../modules/scraper-management')
)
const SearchContainer = lazy(() => import(/* webpackChunkName: 'search' */ '../modules/search'))
const Signup = lazy(
  () => import(/* webpackChunkName: 'auth-signup' */ '../modules/auth/components/Signup')
)
const UserSearchesContainer = lazy(
  () => import(/* webpackChunkName: 'userSearches' */ '../modules/userSearches')
)
const WorkflowAutomation = lazy(
  () =>
    import(
      /* webpackChunkName: 'workflow-automation' */ '../modules/workflow-automation/components/WorkflowAutomationPage'
    )
)

const Report102 = lazy(() => import(/* webpackChunkName: 'report-102' */ '../modules/report-102'))

const AuthenticatedApp: React.FC = () => {
  const [
    canAccessPlatform,
    canAccessTable,
    canAccessExpert,
    isOarViewer,
    isIdsViewer,
    isFreeHealthDashboardUser,
  ] = useSelector(
    makeGetAccesses([
      Access.Platform,
      Access.Table,
      Access.ExpertSearch,
      Access.OarViewer,
      Access.IdsViewer,
      Access.FreeHealthDashboard,
    ])
  )
  const shouldRedirectToFreeHealthDashboard = isFreeHealthDashboardUser
    ? ![canAccessPlatform, isOarViewer, isIdsViewer].every(Boolean)
    : false

  return (
    <>
      <Dialogue />
      <FeatureModal />
      <Suspense fallback={<JuristatLoader />}>
        <Switch>
          <Route exact path="/">
            <Redirect
              to={
                shouldRedirectToFreeHealthDashboard
                  ? '/dashboards/prosecution-health'
                  : canAccessPlatform || canAccessExpert
                  ? '/home'
                  : canAccessTable
                  ? '/search/table'
                  : '/search'
              }
            />
          </Route>
          <Route path="/account-management">
            <AccountManagement />
          </Route>
          <Route path="/alerts-configuration">
            <AlertsConfiguration />
          </Route>
          <ProtectedRoute
            canAccess={Access.AccountTools}
            component={AccountTools}
            path="/account-tools"
            redirect="/"
          />
          <ProtectedRoute
            canAccess={Access.AccountTools}
            component={ScraperManagement}
            path="/scraper-management/:groupId?"
            redirect="/"
          />
          <ProtectedRoute
            canAccess={[Access.IdsViewer, Access.WorkflowAutomation]}
            component={WorkflowAutomation}
            path={['/ids', '/oars']}
            redirect="/"
          />
          <ProtectedRoute
            canAccess={[Access.HumanTasks]}
            component={WorkflowAutomation}
            path={['/analyst-tasks']}
            redirect="/"
          />
          <Route path="/dashboards/:dashboard?">
            <Dashboards />
          </Route>
          <ProtectedRoute canAccess={Access.Api} path="/api" redirect="/">
            <ApiConsole />
          </ProtectedRoute>
          <Route path="/application/:appno">
            <ApplicationContainer />
          </Route>
          <Route path="/download">
            <DownloadFile />
          </Route>
          <ProtectedRoute
            canAccess={Access.CustomerPpairNumberSettings}
            component={Sponsorships}
            path="/sponsorships"
            redirect="/"
          />
          <Route path="/omnisearch">
            <Omnisearch />
          </Route>
          <Route path="/personal">
            <RedirectToPersonal />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route path="/tos">
            <TermsOfSale />
          </Route>
          <Route path="/tou">
            <TermsOfUse />
          </Route>
          <ProtectedRoute canAccess={Access.ExpertSearch} path="/search/expert" redirect="/">
            <ExpertSearch />
          </ProtectedRoute>
          <Route path="/search/:view?">
            <SearchContainer />
          </Route>
          <Route path="/searches/:view">
            <UserSearchesContainer />
          </Route>
          <ProtectedRoute canAccess={Access.ExpertSearch} path="/expert/:id" redirect="/">
            <Expert />
          </ProtectedRoute>
          <ProtectedRoute canAccess={Access.ExpertSearch} path="/patent/:id" redirect="/">
            <Patent />
          </ProtectedRoute>
          <ProtectedRoute
            canAccess={[Access.Platform, Access.ExpertSearch]}
            path="/home"
            redirect="/"
          >
            <Home />
          </ProtectedRoute>
          {/* Must be this way otherwise users get redirected to `/` */}
          {canAccessPlatform && (
            <Route path="/examiner/:id?">
              <Examiner />
            </Route>
          )}
          {canAccessPlatform && (
            <Route path="/drafting">
              <Drafting />
            </Route>
          )}
          {canAccessPlatform && (
            <Route path="/:entity(artunit|assignee-at-disp|attorney-at-disp|current-assignee|current-attorney|current-firm|firm-at-disp|cpc|techcenter|uspc)/:id">
              <Intelligence />
            </Route>
          )}
          {canAccessPlatform && (
            <Route path="/:entity(uspto)">
              <Intelligence />
            </Route>
          )}
          {canAccessPlatform && (
            <Route path="/102-forward-cite">
              <Report102 />
            </Route>
          )}
        </Switch>
      </Suspense>
    </>
  )
}

const App: React.FC = () => {
  const accessToken = useSelector(getAccessToken)
  const authView = useSelector(getAuthView)
  const pathname = useSelector(getPathname)
  const userHasSession = useSelector(hasSession)
  const isAuthenticating = authView.type === HttpStatus.Fetching

  usePendo()
  useIntercom()

  return (
    <Suspense fallback={<Splash />}>
      <Switch>
        <Route path="/signin">
          <Login />
          <div className={styles.product}>
            <ProductInfo />
          </div>
        </Route>
        <Route path="/signup/:groupToken?">
          <Signup />
        </Route>
        <Route path="/confirm-email/:token?">
          <ConfirmEmail />
        </Route>
        <Route path="/return">
          <ReturnHandler />
        </Route>
        <Route>
          <IntercomActionsProvider>
            <DemoModeContextProvider>
              <FilterContextProvider>
                <div className={styles.main}>
                  <div className={styles.navContainer} id="nav-container">
                    <NavBar className={styles.navBar} />
                    <Switch>
                      <Route exact path="/">
                        <NavDrawer />
                      </Route>
                      <Route path="/search/expert" />
                      <Route path="/:report(uspto|home|examiner|search|artunit|assignee-at-disp|attorney-at-disp|current-assignee|current-attorney|current-firm|firm-at-disp|cpc|techcenter|uspc)/:id?">
                        <NavDrawer />
                      </Route>
                      <Route path="/dashboards/:dashboard(prosecution-metrics|roi-metrics|[0-9a-f]{8}-.+)">
                        <NavDrawer />
                      </Route>
                    </Switch>
                  </div>
                  <div className={styles.contentContainer}>
                    <div className={styles.content}>
                      <NotificationContainer />
                      {userHasSession ? (
                        <ClientMatterNumberProvider>
                          <AuthenticatedApp />
                        </ClientMatterNumberProvider>
                      ) : null}
                    </div>
                  </div>
                </div>
              </FilterContextProvider>
            </DemoModeContextProvider>
          </IntercomActionsProvider>
        </Route>
      </Switch>
      <MaybeRender
        render={Splash}
        when={
          pathname !== '/signin' &&
          !pathname.startsWith('/signup') &&
          !pathname.startsWith('/confirm-email') &&
          (!userHasSession || (isAuthenticating && !accessToken))
        }
      />
    </Suspense>
  )
}

export default App
